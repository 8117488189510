<template>
  <section>
    <cosecha-urgente></cosecha-urgente>
    <screenshot name-pdf="Identificacion Niveles Defectos"></screenshot>
    <b-card>
      <b-row>
        <b-col sm="12" class="d-flex justify-content-end">
          <feather-icon icon="PhoneIcon" size="24" class="mr-2" />
          <feather-icon icon="MailIcon" size="24" />
        </b-col>
      </b-row>
      <b-card-body>
        <b-row>
          <b-col>
            <b-form-group
              class="label-defecto"
              label="Seleccionar tipo de defecto"
              label-for="tipoDefecto"
              label-size="lg"
            >
              <b-form-select
                id="tipoDefecto"
                @change="changeInfo($event)"
                class="w-100 mr-1"
                v-model="selected_defecto"
                :options="options_defectos"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card v-if="selected_defecto == null">
      <b-card-title> </b-card-title>
      <b-card-body> Seleccione alguna opción </b-card-body>
    </b-card>

    <b-card v-else>
      <b-card-title> {{ title }} </b-card-title>
      <b-card-body>
        <b-row>
          <b-col sm="12">
            <p>
              {{ description }}
            </p>
          </b-col>

          <b-col sm="12">
            <b-table
              striped
              :items="items"
              :fields="fields"
              responsive
              class="rounded-bottom mb-0"
            >
            </b-table>
          </b-col>
        </b-row>

        <b-row class="mt-3 gallery-height d-flex justify-content-center">
          <!-- MINIATURAS -->
          <b-col sm="3" class="mins-container text-center">
            <div
              class="mt-2"
              v-for="(pic, index) in pictures_selected_defecto"
              :key="index"
            >
              <div
                v-bind:style="{
                  'border-color': pic.color,
                  'border-style': 'solid',
                  'border-width': 1 + 'px',
                }"
              >
                <div
                  class="d-flex justify-content-center"
                  v-bind:style="{ 'background-color': pic.color }"
                >
                  <h5 class="mt-1 galery-min-title-text">
                    {{ pic.description }}
                  </h5>
                </div>
                <b-img
                  :src="getImgUrl(pic.url)"
                  :alt="pic.description"
                  @click="changeCard(pic)"
                  class="nivel-defecto mt-1 mb-1"
                  fluid-grow
                  thumbnail
                  rounded
                />
              </div>
            </div>
          </b-col>

          <!-- IMAGENES -->

          <b-col
            sm="8"
            class="
              max-container
              d-flex
              justify-content-center
              galery-img-background
            "
            v-bind:style="{ 'background-color': currentCard.color }"
          >
            <b-card
              class="galery-img-background"
              v-bind:style="{ 'background-color': currentCard.color }"
            >
              <b-card-title
                class="text-center title-card galery-min-title-text"
                >{{ currentCard.title }}</b-card-title
              >
              <b-card-body
                class="text-center galery-img-background"
                v-bind:style="{ 'background-color': currentCard.color }"
              >
                <p class="text-center galery-min-title-text">
                  <b> {{ currentCard.desc }}</b>
                </p>
                <b-img
                  :src="currentCard.img"
                  :alt="currentCard.desc"
                  fluid-grow
                />
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import CosechaUrgente from "@/views/clients/blumar/components/CosechaUrgente";
import Screenshot from "@/views/clients/blumar/components/Screenshot";
import {
  BButton,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BFormSelect,
  BTable,
  BImg,
  BImgLazy,
  BFormGroup,
} from "bootstrap-vue";

export default {
  data() {
    return {
      title: "",
      description: "",
      items: [],
      fields: [],
      pictures: [],
      currentDefecto: null,
      pictures_selected_defecto: null,
      currentCard: {
        title: "",
        desc: "",
        img: "",
        color: "",
      },
      currentImg: "",
      selected_defecto: null,
      options_defectos: [
        { value: null, text: "Seleccionar por" },
        { value: "0", text: "Branquias" },
        { value: "1", text: "Lesión Caligus" },
        { value: "2", text: "Lesión Lobos" },
        { value: "3", text: "Madurez" },
        { value: "4", text: "Lesión SRS" },
      ],

      defectos: [
        {
          title: "Branquias",
          description:
            "Descripción del grado de deterioro de las branquias mediante observación visual de su coloración e integridad, clasificado de acuerdo a la siguiente tabla:",
          pictures: [
            {
              url: "/0/0",
              description: "Condición de Branquias Normales (Grado 0)",
              color: "#B9CCE4",
            },
            {
              url: "/0/1",
              description: "Condición de Branquias Leve (Grado 1)",
              color: "#003D7E",
            },
            {
              url: "/0/2",
              description: "Condición de Branquias Medio (Grado 2)",
              color: "#F0AE33",
            },
            {
              url: "/0/3",
              description: "Condición de Branquias Evidentes (Grado 3)",
              color: " #E26629",
            },
          ],
          columns: [
            { key: "grado", label: "Grado" },
            { key: "calificacion", label: "Calificación" },
            { key: "descripcion", label: "Lesiones" },
          ],
          rows: [
            {
              key: "0",
              grado: "0",
              calificacion: "Sin Lesiones",
              descripcion: "Ausencia",
            },
            {
              key: "1",
              grado: "1",
              calificacion: "Lesiones Leve",
              descripcion:
                "1 herida en la cola o cuerpo cicatrizada de hasta 0.5 cm sin presencia de interrupción de la piel ",
            },
            {
              key: "2",
              grado: "2",
              calificacion: "Lesiones Media",
              descripcion: "Hasta 2 heridas < 1.5 cm ",
            },
            {
              key: "3",
              grado: "3",
              calificacion: "Lesiones Evidentes",
              descripcion: "Lo que no clasifica para lesiones leve y media ",
            },
          ],
        },
        {
          title: "Caligus",
          description:
            "Esta característica es medida visualmente contabilizando la cantidad de puntos rojizos (petequias), presentes en la piel del pescado y clasificada de acuerdo a la siguiente tabla:",
          pictures: [
            {
              url: "/1/0",
              description: "Caligus Sin Lesiones Grado 0",
              color: "#B9CCE4",
            },
            {
              url: "/1/1",
              description: "Caligus Lesiones Leves Grado 1",
              color: "#003D7E",
            },
            {
              url: "/1/2",
              description: "Caligus Lesiones Leves Grado 2",
              color: "#F0AE33",
            },
            {
              url: "/1/3",
              description: "Caligus Hasta evidentes Grado 3",
              color: " #E26629",
            },
          ],
          columns: [
            { key: "grado", label: "Grado" },
            { key: "calificacion", label: "Calificación" },
            { key: "descripcion", label: "Lesiones" },
          ],
          rows: [
            {
              key: "0",
              grado: "0",
              calificacion: "Sin Lesiones",
              descripcion: "0",
            },
            {
              key: "1",
              grado: "1",
              calificacion: "Lesiones Leves",
              descripcion: "Hasta 4 lesiones, enrojecimiento leve de la piel",
            },
            {
              key: "2",
              grado: "2",
              calificacion: "Lesiones Leves",
              descripcion: "5 a 10 lesiones, enrojecimiento leve de la piel",
            },
            {
              key: "3",
              grado: "3",
              calificacion: "Hasta Evidentes",
              descripcion:
                ">10 lesiones, enrojecimiento de la piel sin restricción",
            },
          ],
        },
        {
          title: "Lobos",
          description: "",
          pictures: [
            {
              url: "/2/0",
              description: "desc img 1",
              color: "#B9CCE4",
            },
            {
              url: "/2/1",
              description: "desc img 1",
              color: "#003D7E",
            },
            {
              url: "/2/2",
              description: "desc img 1",
              color: "#F0AE33",
            },
            {
              url: "/2/3",
              description: "desc img 1",
              color: " #E26629",
            },
          ],
          columns: [
            { key: "grado", label: "Grado" },
            { key: "calificacion", label: "Calificación" },
            { key: "descripcion", label: "Lesiones" },
          ],
          rows: [
            {
              key: "0",
              grado: "0",
              calificacion: "Sin Lesiones",
              descripcion: "Ausencia",
            },
            {
              key: "1",
              grado: "1",
              calificacion: "Lesiones Leve",
              descripcion:
                "1 herida en la cola o cuerpo cicatrizada de hasta 0.5 cm sin presencia de interrupción de la piel ",
            },
            {
              key: "2",
              grado: "2",
              calificacion: "Lesiones Media",
              descripcion: "Hasta 2 heridas < 1.5 cm ",
            },
            {
              key: "3",
              grado: "3",
              calificacion: "Lesiones Evidentes",
              descripcion: "Lo que no clasifica para lesiones leve y media ",
            },
          ],
        },
        {
          title: "Madurez",
          description:
            "Es determinada visualmente en función de la coloración de la piel y la deformación mandibular del pescado y clasificada de acuerdo a la siguiente tabla:",
          pictures: [
            {
              url: "/3/0",
              description: "Sin Madurez en piel (Grado 0)",
              color: "#B9CCE4",
            },
            {
              url: "/3/1",
              description: "Madurez Leve en piel (Grado 1)",
              color: "#003D7E",
            },
            {
              url: "/3/2",
              description: "Madurez Media en piel (Grado 2)",
              color: "#F0AE33",
            },
            {
              url: "/3/3",
              description: "Madurez Evidente en piel (Grado 3)",
              color: " #E26629",
            },
          ],
          columns: [
            { key: "grado", label: "Grado" },
            { key: "calificacion", label: "Calificación" },
            { key: "colorpiel", label: "Color piel" },
            { key: "descripcion", label: "Deformación Mandibular (Gancho)(*)" },
          ],
          rows: [
            {
              key: "0",
              grado: "0",
              calificacion: "Sin Madurez",
              colorpiel: "Lomo Plateado \n Belly blanco",
              descripcion: "Ausencia de Gancho",
            },
            {
              key: "1",
              grado: "1",
              calificacion: "Madurez Leve",
              colorpiel: "Lomo Plateado \n Belly levemente oscurecido",
              descripcion: "Gancho Leve",
            },
            {
              key: "2",
              grado: "2",
              calificacion: "Madurez Media",
              colorpiel: "Levemente Oscura",
              descripcion: "Hasta medio",
            },
            {
              key: "3",
              grado: "3",
              calificacion: "Madurez Evidente",
              colorpiel: "Oscura Parduzco",
              descripcion:
                "Ausencia de Gancho, gancho Leve, \n Medio o Evidente",
            },
          ],
        },
        {
          title: "SRS",
          description:
            "El nivel de SRS se mide visualmente en función del número de heridas atribuibles a dicha enfermedad (redondas, cóncavas, de bordes definidos) presentes a nivel de la piel del pescado y la presencia de sangre sobre estas, siendo clasificado de acuerdo a la siguiente tabla:",
          pictures: [
            {
              url: "/4/0",
              description: "SRS Sin Lesiones (Grado 0)",
              color: "#B9CCE4",
            },
            {
              url: "/4/1",
              description: "SRS Lesiones Leve (Grado 1)",
              color: "#003D7E",
            },
            {
              url: "/4/2",
              description: "SRS Lesiones Media (Grado 2)",
              color: "#F0AE33",
            },
            {
              url: "/4/3",
              description: "SRS Lesiones Evidentes (Grado 3)",
              color: " #E26629",
            },
          ],
          columns: [
            { key: "grado", label: "Grado" },
            { key: "calificacion", label: "Calificación" },
            { key: "descripcion", label: "Número de Heridas SRS" },
          ],
          rows: [
            {
              key: "0",
              grado: "0",
              calificacion: "Sin Lesiones",
              descripcion: "Ausencia",
            },
            {
              key: "1",
              grado: "1",
              calificacion: "Lesiones Leve",
              descripcion:
                "1 herida en la cola o cuerpo cicatrizada de hasta 0.5 cm sin presencia de interrupción de la piel ",
            },
            {
              key: "2",
              grado: "2",
              calificacion: "Lesiones Media",
              descripcion: "Hasta 2 heridas < 1.5 cm ",
            },
            {
              key: "3",
              grado: "3",
              calificacion: "Lesiones Evidentes",
              descripcion: "Lo que no clasifica para lesiones leve y media ",
            },
          ],
        },
      ],
    };
  },
  methods: {
    changeInfo(event) {
      let defecto = this.defectos[event];
      this.currentDefecto = defecto;
      this.title = defecto.title;
      this.description = defecto.description;
      this.fields = defecto.columns;
      this.items = defecto.rows;

      this.pictures = defecto.pictures;
      console.log(defecto.pictures);
      this.pictures_selected_defecto = defecto.pictures;

      this.changeCard(defecto.pictures[0]);
    },

    getImgUrl(nameFile) {
      return require("@/assets/images/identificacion-niveles-defectos" +
        nameFile +
        ".png");
    },
    changeCard(picture) {
      let url = picture.url;

      let pic = url.substr(url.length - 1);
      this.currentCard.img = this.getImgUrl(url);
      this.currentCard.desc = this.currentDefecto.rows[pic].descripcion;
      this.currentCard.title = this.currentDefecto.pictures[pic].description;
      this.currentCard.color = picture.color;
      console.log(this.currentCard);
    },
  },
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BFormSelect,
    BTable,
    BImg,
    BImgLazy,
    BFormGroup,
    CosechaUrgente,
    Screenshot,
  },
};
</script>

<style scoped>
div.card-body > img {
  max-height: 330px;
}

div.galeria-defectos-min > img {
  max-height: 100px;
}
.mins-container {
  height: 510px !important;
  overflow: auto;
}

.max-container {
  height: 510px !important;
  margin-left: 70px !important;
}

/* .galeria-defectos-min {
  border: 2px solid #ff7f50;
} */
div.max-container.col-sm-9 > div {
  width: 570px;
}

.title-card {
  margin-bottom: 0px !important;
}

/* MINIATURAS */
/* .galery-min-title{
  background-color: blue !important;
} */
.galery-min-title-text {
  color: #f7f7f7 !important;
}

/* .border-min{
    border: 1px solid black;
} */

/*IMAGENES*/
/* .galery-img-background{
  background-color: blue;
} */

.galery-min-title-text {
  color: #f7f7f7 !important;
}

.nivel-defecto {
  cursor: pointer;
}
</style>